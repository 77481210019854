<template>
  <div class="files-list-gallery">
    <vue-gallery
      :id="galleryId"
      class="carousel-vuegallery"
      :images="imageList"
      :index="initialIndex"
      @mousedown.native.stop="clickGallery"
      @onopen="galleryOnOpen"
      @close="galleryOnClose"
      @onslideend="currentIndex = $event.index"
      ref="vuegallery"
    ></vue-gallery>

    <div class="carousel-grid ui-row">
      <div
        class="grid-image"
        v-for="(image, i) in gridItems"
        :key="i"
        @mousedown.stop="openSlide(i)"
        :style="{ backgroundImage:'url('+image.thumbnail+')' }"
      ></div>

      <div
        class="grid-more grid-image"
        v-if="hiddenCount"
        @mousedown.stop="openSlide(previewLimit-1)"
      >{{hiddenCount + 1}}+</div>
    </div>
  </div>
</template>

<script>
/*
Este componente <vue-gallery> no es consistente con la declaracion de eventos, no tiene un evento "click"
para informar cuando se hizo click en un slide, y algunos nombres de evento tienen prefijo "on" y otros no (grrr)

@mousedown.native="clickGallery"
@onopen="galleryOnOpen"
@close="galleryOnClose"
@onslideend="currentIndex = $event.index"

Tampoco hay forma de cerrar la galeria sin que se ejecute el evento close.  Es decir, no hay forma de saber
cuando la galería se cerró porque se modifico el parametro :index externamente, o porque el usuario hizo click en "X" o arrastró el slide

Tampoco tiene un listener nativo para el boton "atras".


files = [
  {
    title: "Titulo a mostrar",
    url: "URL de la imagen original",
    thumbnail: "URL del thumbnail",
    preview: "URL del preview (imagen expandida)",
  },
  ...
];

*/
import VueGallery from 'vue-gallery';
var globalGalCount = 0;

export default {
  name: 'files-list-gallery',
  components: { VueGallery },

  props: {
    files: {
      type: Array,
      required: false,
      default: () => []
    },

    previewLimit: {
      type: [Number, String],
      required: false,
      default: 4
    }
  },

  data() {
    return {
      galleryId: null,
      initialIndex: null,
      currentIndex: null,
      backButtonPressed: false
    };
  },

  mounted() {
    this.galleryId = 'vue_gallery_' + (++globalGalCount);
  },

  computed: {
    imageList() {
      return this.files
        .filter(file => file.preview && file.thumbnail)
        .map(file => ({
          title: file.title,
          href: file.preview,
          thumbnail: file.thumbnail,
          url: file.url
        }));
    },

    gridItems() {
      if (this.imageList.length <= this.previewLimit) {
        return this.imageList;
      }
      return this.imageList.slice(0, this.previewLimit - 1);
    },

    hiddenCount() {
      return Math.max(this.imageList.length - this.previewLimit, 0);
    }
  },

  methods: {
    openSlide(index) {
      this.initialIndex = index;
      this.currentIndex = index;
    },

    galleryOnOpen() {
      this.backButtonPressed = false;
      window.addEventListener('popstate', this.onPopstate);
      history.pushState('filesystem-carousel', '', '');
    },

    galleryOnClose() {
      if (!this.backButtonPressed) {
        history.go(-1); // disparar onPopstate
      }
    },

    clickGallery($event) {
      if ($event.target.classList.contains('slide-content')) {
        let file = this.imageList[this.currentIndex];
        this.$emit('click-file', file);
      }
    },

    onPopstate() {
      this.backButtonPressed = true;
      this.initialIndex = null; // esto dispara galleryOnClose
      this.currentIndex = null;

      window.removeEventListener('popstate', this.onPopstate);
    }
  }
};
</script>

<style lang="scss">
.files-list-gallery {
  .carousel-grid {
    .grid-image {
      cursor: pointer;
      flex: none;
      width: 120px;
      height: 100px;

      border: 1px solid #f3f3f3;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 0;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border: 1px solid var(--ui-color-primary);
      }
    }

    .grid-more {
      background-color: rgba(0, 0, 0, 0.85);
      color: #fff;
      font-size: 28px;
    }
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>